import React from "react";
import { Container, Row, Col } from "reactstrap";
import { IconButton } from "@material-ui/core";

import {
  dateFormatLong,
  arrayGroupByDate,
  objects,
  objectCompareByDate
} from "base";

import ModalObjectEdit from "../../ModalObjectEdit";
import Card from "../Card";
import Icon from "../../Icon";
import ModalCommAdd from "../ModalCommAdd";
import Comm from "../Comm";
import ModalComm from "../ModalComm";
import Loading from "../../Loading";

class Communications extends React.Component {
  constructor(props) {
    super(props);
    this.commsRef = React.createRef();
  }

  render() {
    const {
      isLoading,
      newIsOpen,
      editMode,
      isUpdateLoading,
      isUpdateSuccess,
      error,
      companyId,
      current,
      items,
      setCurrent,
      toggleNewIsOpen,
      toggleEditMode,
      filter,
      update
    } = this.props;
    const commsGroupedByDate = arrayGroupByDate(items).sort(
      objectCompareByDate
    );
    return (
      <Card id="tmk-comm" title="Comunicaciones">
        {isLoading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <div ref={this.commsRef} className="scroll">
              <Container>
                {commsGroupedByDate.map(({ date, items }, gi) => (
                  <React.Fragment key={gi}>
                    <div className="tmk-comm-date">{dateFormatLong(date)}</div>
                    {items.sort(objectCompareByDate).map((comm, ci) => (
                      <Comm
                        key={ci}
                        comm={comm}
                        onClick={() => setCurrent(comm)}
                      />
                    ))}
                  </React.Fragment>
                ))}
              </Container>
            </div>
            <Container className="tmk-comm-actions">
              <Row className="text-center">
                <Col>
                  <IconButton>
                    <Icon>search</Icon>
                  </IconButton>
                </Col>
                <Col />
                <Col>
                  <IconButton size="small" onClick={toggleNewIsOpen}>
                    <Icon>plus</Icon>
                  </IconButton>
                </Col>
              </Row>
            </Container>
          </React.Fragment>
        )}

        {current &&
          (editMode ? (
            <ModalObjectEdit
              isOpen={true}
              rounded={true}
              isLoading={isUpdateLoading}
              isSuccess={isUpdateSuccess}
              title="Editar comunicación"
              error={error}
              size="lg"
              object={{
                id: current.id,
                type: objects.comm.type,
                parms: { ...current }
              }}
              submitFunc={update}
              onClose={toggleEditMode}
              onSuccess={() => {
                filter();
                setCurrent(null);
              }}
            />
          ) : (
            <ModalComm
              comm={current}
              onClose={() => setCurrent(null)}
              onEdit={toggleEditMode}
              onSuccess={filter}
            />
          ))}
        {newIsOpen && (
          <ModalCommAdd
            companyId={companyId}
            onClose={toggleNewIsOpen}
            onSuccess={filter}
          />
        )}
      </Card>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isSuccess === this.props.isSuccess) {
      setTimeout(() => {
        if (this.commsRef.current) {
          this.commsRef.current.scrollTo(0, this.commsRef.current.scrollTopMax);
        }
      }, 100);
    }
  }
}

export default Communications;
