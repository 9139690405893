import React from "react";
import { Row, Col } from "reactstrap";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { configGoogleMaps } from "base";

import MasterPage from "../MasterPage";
import Contacts from "../../components/Contacts";
import Loading from "../../components/Loading";
import Snackbar from "../../components/Snackbar";

import mapSyles from "./mapStyles";
import "./style.css";

const position = { lat: -34.88047, lng: -56.15182 };

const mapOptions = {
  streetViewControl: false,
  scaleControl: false,
  mapTypeControl: false,
  panControl: false,
  zoomControl: false,
  rotateControl: false,
  fullscreenControl: false,
  styles: mapSyles
};

const Map100 = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${
      configGoogleMaps.key
    }`,
    loadingElement: <div style={{ height: "calc(100vh - 100px)" }} />,
    containerElement: <div style={{ height: "calc(100vh - 100px)" }} />,
    mapElement: <div style={{ height: "calc(100vh - 100px)" }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={17}
    defaultCenter={{ lat: position.lat - 0.0002, lng: position.lng + 0.0004 }}
    defaultOptions={mapOptions}
  >
    {props.showMarker && (
      <Marker
        position={position}
        defaultAnimation={window.google.maps.Animation.DROP}
      />
    )}
  </GoogleMap>
));

const Map50 = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${
      configGoogleMaps.key
    }`,
    loadingElement: <div style={{ height: "calc(50vh)" }} />,
    containerElement: <div style={{ height: "calc(50vh)" }} />,
    mapElement: <div style={{ height: "calc(50vh)" }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={16}
    defaultCenter={position}
    defaultOptions={mapOptions}
  >
    {props.showMarker && (
      <Marker
        position={position}
        defaultAnimation={window.google.maps.Animation.DROP}
      />
    )}
  </GoogleMap>
));

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMarker: false,
      messages: []
    };
  }

  addMessage = (type, message) => {
    let { messages } = this.state;
    messages.push(<Snackbar type={type} message={message} />);
    this.setState({ messages });
  };

  componentDidMount() {
    document.title = "Contacto";

    setTimeout(() => {
      this.setState({ showMarker: true });
    }, 1000);
  }

  render() {
    const {
      isEmailSendContactLoading,
      isEmailSendContactSuccess,
      name,
      email,
      phone,
      subject,
      message,
      errors,
      windowSize,
      onInputChange,
      onSubmit
    } = this.props;
    const { showMarker, messages } = this.state;

    const colSizes = {
      xs: "12"
    };
    return (
      <MasterPage
        contentPaddingTop={false}
        contentPaddingBottom={false}
        hideFooter
        hideGoTop
      >
        <div className="contact-page">
          {windowSize === "xs" || windowSize === "sm" || windowSize === "md" ? (
            <Map50 showMarker={showMarker} />
          ) : (
            <Map100 showMarker={showMarker} />
          )}
          <div className="contacts-container">
            <div className="card">
              <Contacts extended />
            </div>
          </div>
          <div className="send-message">
            <div className="card">
              {isEmailSendContactSuccess ? (
                <div className="message text-center">
                  <h5>
                    Su mensaje fue enviado correctamente
                    <br />
                    ¡estaremos en contacto en breve!
                  </h5>
                </div>
              ) : isEmailSendContactLoading ? (
                <Loading />
              ) : (
                <form noValidate onSubmit={onSubmit}>
                  <Row>
                    <Col {...colSizes}>
                      <TextField
                        fullWidth
                        error={Boolean(errors.name)}
                        type="text"
                        name="name"
                        className="form-control"
                        label="Nombre"
                        value={name}
                        onChange={onInputChange}
                      />
                      <span className="form-control-error">
                        {errors.name && errors.name}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col {...colSizes}>
                      <TextField
                        fullWidth
                        error={Boolean(errors.email)}
                        type="email"
                        name="email"
                        className="form-control"
                        label="Email"
                        value={email}
                        onChange={onInputChange}
                      />
                      <span className="form-control-error">
                        {errors.email && errors.email}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col {...colSizes}>
                      <TextField
                        fullWidth
                        error={Boolean(errors.phone)}
                        type="text"
                        name="phone"
                        className="form-control"
                        label="Teléfono"
                        value={phone}
                        onChange={onInputChange}
                      />
                      <span className="form-control-error">
                        {errors.phone && errors.phone}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col {...colSizes}>
                      <TextField
                        fullWidth
                        error={Boolean(errors.phone)}
                        type="text"
                        name="subject"
                        className="form-control"
                        label="Asunto"
                        value={subject}
                        onChange={onInputChange}
                      />
                      <span className="form-control-error">
                        {errors.subject && errors.subject}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col {...colSizes}>
                      <TextField
                        fullWidth
                        multiline
                        error={Boolean(errors.phone)}
                        type="text"
                        name="message"
                        className="form-control"
                        label="Mensaje"
                        value={message}
                        onChange={onInputChange}
                      />
                      <span className="form-control-error">
                        {errors.message && errors.message}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col {...colSizes}>
                      <div className="contact-actions text-right">
                        <Button
                          type="submit"
                          size="large"
                          color="primary"
                          variant="contained"
                        >
                          Enviar
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </form>
              )}
            </div>
          </div>
        </div>
        {messages.map((message, i) => {
          return <div key={i}>{message}</div>;
        })}
      </MasterPage>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.emailSendContactError !== "" &&
      prevProps.emailSendContactError !== this.props.emailSendContactError
    ) {
      this.addMessage("error", this.props.emailSendContactError);
    }
  }
}

export default ContactPage;
