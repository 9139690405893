import React from "react";
import { Redirect } from "react-router-dom";
import { Modal, ModalBody, Container, Row, Col } from "reactstrap";
import Swiper from "react-id-swiper";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import { numberFormat, numberTypes, objects } from "base";

import { imageGet } from "../../helpers";

import MasterPage from "../MasterPage";
import Snackbar from "../../components/Snackbar";
import Loading from "../../components/Loading";
import Divider from "../../components/Divider";
import Icon from "../../components/Icon";
import Stock from "../../components/Stock";

import "./style.css";
import ProductCarousel from "../../components/ProductCarousel";
import ModalProductEdit from "../../components/ModalProductEdit";
import ObjectSection from "../../components/ObjectSection";

const Edit = props => {
  const { absolute, object, setCurrent } = props;
  return (
    <div
      className={`product-admin-actions ${
        absolute ? "absolute" : ""
      } text-right`}
    >
      <button onClick={() => setCurrent(object)}>
        Editar<Icon>edit</Icon>
      </button>
    </div>
  );
};

const Images = props => {
  const { imageIndex, images, setImageIndex } = props;
  let swiper = null;
  return (
    <>
      <Swiper
        ref={node => {
          if (node) swiper = node.swiper;
        }}
        activeSlideKey={imageIndex.toString()}
        on={{ slideChange: () => setImageIndex(swiper.activeIndex) }}
      >
        {images.map(({ image }, i) => (
          <div
            key={i}
            className="product-detail-img"
            style={{ backgroundImage: `url(${image})` }}
          />
        ))}
      </Swiper>
      <div className="product-detail-img-tumbs">
        {images.map(({ image }, i) => (
          <div
            key={i}
            className={`product-detail-img-tumb ${
              imageIndex === i ? "active" : ""
            }`}
            style={{ backgroundImage: `url(${image})` }}
            onClick={() => setImageIndex(i)}
          />
        ))}
      </div>
    </>
  );
};

const Detail = props => {
  const {
    isSeller,
    stockLoading,
    stockQuant,
    imageIndex,
    product,
    user,
    cartAddItem,
    setCurrent,
    setImageIndex
  } = props;
  const {
    isOriginal,
    isPublic,
    code,
    name,
    description,
    price,
    images
  } = product;
  const img = imageGet(code, images);
  const style = { backgroundImage: `url(${img})` };

  return (
    <div className="product-detail card">
      {isSeller && (
        <Edit
          absolute
          object={{
            type: objects.product.type,
            // isAdd,
            id: code,
            parms: {
              ...product,
              image:
                product && product.images && product.images.length > 0
                  ? product.images[0]
                  : null
            }
          }}
          setCurrent={setCurrent}
        />
      )}
      <Row>
        <Col xs={12} lg={6}>
          {images.length > 1 ? (
            <Images
              imageIndex={imageIndex}
              images={images}
              setImageIndex={setImageIndex}
            />
          ) : (
            <div className="product-detail-img" style={style} />
          )}
        </Col>
        <Col xs={12} lg={6}>
          <div className="roduct-detail-right">
            <span className="product-detail-title">{name}</span>
            <br />
            <span className="product-detail-code">
              <small>Cod</small> {code}
            </span>
            <br />
            {!isPublic && <div className="tag inactive">Inactivo</div>}
            {isOriginal && <div className="tag original">Original</div>}
            <br />
            <span className="product-detail-price">
              <small>U$S</small> {numberFormat(numberTypes.PRICE, price)}
            </span>{" "}
            <span className="product-detail-price-obs">
              <small>IVA inc</small>
            </span>
            <Divider marginTop />
            <span className="product-detail-stock">
              Stock{" "}
              <Stock
                isLoading={stockLoading}
                isSeller={user ? user.isSeller : false}
                quant={stockQuant}
              />
            </span>
            <Button
              fullWidth
              disabled={stockQuant <= 0}
              variant="contained"
              size="large"
              color="primary"
              className="product-detail-button"
              onClick={() => cartAddItem(product)}
            >
              Comprar <Icon>shopping-bag</Icon>
            </Button>
          </div>
        </Col>
      </Row>
      <Divider marginTop />
      <Row>
        <Col xs={12}>
          <p>{description}</p>
        </Col>
      </Row>
    </div>
  );
};

const RelatedLinks = props => {
  const {
    isSeller,
    productCode,
    links,
    modalRelatedLinksOpen,
    toggle,
    setCurrent
  } = props;

  return (
    <div className="product-detail-related-links">
      <Divider />
      <Container>
        <Row noGutters>
          <Col>
            <div className="section-title">Links</div>
          </Col>
          <Col>
            <Col>
              {isSeller && (
                <Edit setCurrent={() => toggle("modalRelatedLinksOpen")} />
              )}
            </Col>
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <div className="card list">
              <Container>
                {links.map(({ name, url }, i) => {
                  return (
                    <a
                      key={i}
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Row>
                        <Col>{name}</Col>
                      </Row>
                      <div />
                    </a>
                  );
                })}
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={modalRelatedLinksOpen} className="modal-object-edit card">
        <ModalBody className="no-padding">
          <IconButton
            className="modal-top-close"
            onClick={() => toggle("modalRelatedLinksOpen")}
          >
            <Icon>times</Icon>
          </IconButton>
          <ObjectSection
            edit
            isList
            id={productCode}
            type={objects.productRelatedLink.type}
            title="Links"
            items={links.map(link => {
              return {
                isDefault: link.isDefault,
                label: link.name,
                value: link.url,
                parms: link
              };
            })}
            setCurrent={setCurrent}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

const Specs = props => {
  const {
    isSeller,
    modalSepcsOpen,
    productCode,
    specs,
    toggle,
    setCurrent
  } = props;

  return (
    <div className="product-detail-related-links">
      <Divider />
      <Container>
        <Row noGutters>
          <Col>
            <div className="section-title">Especificaciones</div>
          </Col>
          <Col>
            {isSeller && <Edit setCurrent={() => toggle("modalSepcsOpen")} />}
          </Col>
        </Row>
        <Row noGutters>
          <Col>
            <div className="card list not-hover">
              <Container>
                {specs.map(({ key, value }, i) => {
                  return (
                    <Row key={i}>
                      <Col xs="12" md="5">
                        <label>{key}</label>
                      </Col>
                      <Col xs="12" md="7">
                        {value}
                      </Col>
                    </Row>
                  );
                })}
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={modalSepcsOpen} className="modal-object-edit card">
        <ModalBody className="no-padding">
          <IconButton
            className="modal-top-close"
            onClick={() => toggle("modalSepcsOpen")}
          >
            <Icon>times</Icon>
          </IconButton>
          <ObjectSection
            edit
            isList
            id={productCode}
            type={objects.productSpecification.type}
            title="Especificaciones"
            items={specs.map(spec => {
              return {
                isDefault: spec.isDefault,
                label: spec.key,
                value: spec.value,
                parms: spec
              };
            })}
            setCurrent={setCurrent}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

class ProductDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageIndex: 0,
      messages: []
    };
  }

  cartAddItem = product => {
    this.props.cartAddItem(product);
    let { messages } = this.state;
    messages.push(<Snackbar type="cartItemSuccess" />);
    this.setState({ messages });
  };

  setImageIndex = index => this.setState({ imageIndex: index });

  componentDidMount() {
    document.title = "Robotec";
  }

  render() {
    const {
      productIsLoading,
      stockLoading,
      relatedIsLoading,
      modalRelatedLinksOpen,
      modalSepcsOpen,
      windowSize,
      stockQuant,
      user,
      product,
      related,
      current,
      setCurrent,
      toggle
    } = this.props;

    const isSeller = user && user.isSeller;
    const { imageIndex, messages } = this.state;
    return (
      <MasterPage>
        <div className="product-detail">
          {productIsLoading ? (
            <Loading />
          ) : !product || !product.code ? (
            <Redirect to="/404" />
          ) : (
            <div>
              <Container>
                <Detail
                  isSeller={isSeller}
                  stockLoading={stockLoading}
                  stockQuant={stockQuant}
                  imageIndex={imageIndex}
                  product={product}
                  user={user}
                  cartAddItem={this.cartAddItem}
                  setCurrent={setCurrent}
                  setImageIndex={this.setImageIndex}
                />
              </Container>
              {(product.specifications.length > 0 || isSeller) && (
                <Specs
                  isSeller={isSeller}
                  modalSepcsOpen={modalSepcsOpen}
                  productCode={product.code}
                  specs={product.specifications}
                  setCurrent={setCurrent}
                  toggle={toggle}
                />
              )}
              {(product.relatedLinks.length > 0 || isSeller) && (
                <RelatedLinks
                  isSeller={isSeller}
                  modalRelatedLinksOpen={modalRelatedLinksOpen}
                  productCode={product.code}
                  links={product.relatedLinks}
                  setCurrent={setCurrent}
                  toggle={toggle}
                />
              )}
            </div>
          )}
          {!relatedIsLoading && related.length > 0 && (
            <ProductCarousel
              windowSize={windowSize}
              items={[{ title: "Tambien te puede interesar", items: related }]}
            />
          )}
          {messages.map((message, i) => {
            return <div key={i}>{message}</div>;
          })}
        </div>
        <ModalProductEdit
          isAdd={false}
          object={current}
          setCurrent={setCurrent}
          onSuccess={() => window.location.reload(true)}
        />
      </MasterPage>
    );
  }
}

export default ProductDetailPage;
