import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Button, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { objectCompareByName } from "base";

import ModalObjectEdit from "../ModalObjectEdit";
import ModalCompanyGroupSelect from "./ModalCompanyGroupSelect";
import ModalCompanyAdd from "./ModalCompanyAdd";
import Icon from "../Icon";
import CompanyInfo from "./CompanyInfo";
import CompanyMap from "./CompanyMap";
import Communications from "./Communications";
import Images from "./CompanyImages";
import Schedule from "./Schedule";

import "./style.css";

const COMPANY_GROUP_SEARCH = "COMPANY_GROUP_SEARCH";
const COMPANY_COMANY_ADD = "COMPANY_COMANY_ADD";
const SCHEDULE = "SCHEDULE";

const styles = {
  tooltipPopper: {
    zIndex: "999"
  },
  tooltipTransp: {
    width: "20px",
    height: "20px",
    marginLeft: "0",
    padding: "0",
    backgroundColor: "transparent",
    color: "black"
  },
  btnClose: {
    minWidth: "unset",
    fontSize: "11px",
    borderRadius: "20px!important",
    marginBottom: "6px"
  }
};

const Actions = props => {
  const {
    options,
    company,
    currentOption,
    companyGroupsOpen,
    classes,
    setOption,
    companyFind,
    clearCompany,
    companyGroupsOpenDelete
  } = props;

  return (
    <div id="tmk-actions" className={`tmk-actions ${company ? "active" : ""}`}>
      <Container>
        <Row noGutters>
          <Col>
            <div className="tmk-title">Telemarketing</div>
          </Col>
        </Row>
        <div className="tmk-actions-content">
          <ul className="tmk-actions-fixed">
            {options.map(({ id, icon, title, modalParms, Modal }, i) => {
              return (
                <li key={i}>
                  <Row noGutters>
                    <Col>
                      <Button
                        onClick={() =>
                          setOption(id !== currentOption ? id : "")
                        }
                      >
                        <Row key={i} noGutters>
                          <Col xs="auto">
                            <div>
                              <Icon>{icon}</Icon>
                            </div>
                          </Col>
                          <Col className="tmk-actions-title">{title}</Col>
                        </Row>
                      </Button>
                    </Col>
                  </Row>
                  {currentOption === id && Modal && (
                    <Modal
                      rounded={true}
                      isOpen={true}
                      onClose={() => setOption(null)}
                      {...modalParms}
                    />
                  )}
                </li>
              );
            })}
          </ul>
          <ul className="tmk-actions-open">
            {companyGroupsOpen
              .sort(objectCompareByName)
              .map(({ id, name, companies }, i) => (
                <li key={i}>
                  <Row noGutters>
                    <Col>
                      <Tooltip
                        interactive
                        placement="right"
                        classes={{
                          popper: classes.tooltipPopper,
                          tooltip: classes.tooltipTransp
                        }}
                        title={
                          <Button
                            className="tmk-actions-btn-close"
                            classes={{ root: classes.btnClose }}
                            onClick={() => companyGroupsOpenDelete(id)}
                          >
                            <Icon>times</Icon>
                          </Button>
                        }
                      >
                        <Button>{name}</Button>
                      </Tooltip>
                    </Col>
                  </Row>
                  <ul className="tmk-actions-open-items">
                    {companies.map(({ id, name }, ii) => {
                      return (
                        <li key={ii}>
                          <Row noGutters>
                            <Col>
                              <Tooltip
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                interactive
                                open={Boolean(
                                  company &&
                                    parseInt(id) === parseInt(company.id)
                                )}
                                placement="right"
                                classes={{
                                  popper: classes.tooltipPopper,
                                  tooltip: classes.tooltipTransp
                                }}
                                title={
                                  <Button
                                    className="tmk-actions-btn-close"
                                    classes={{ root: classes.btnClose }}
                                    onClick={() => clearCompany()}
                                  >
                                    <Icon>times</Icon>
                                  </Button>
                                }
                              >
                                <Button
                                  onClick={() => {
                                    setOption(null);
                                    companyFind(id);
                                  }}
                                >
                                  {name}
                                </Button>
                              </Tooltip>
                            </Col>
                          </Row>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ))}
          </ul>
        </div>
      </Container>
    </div>
  );
};

class Telemarketing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOption: null //COMPANY_GROUP_SEARCH
    };
    this.options = [
      {
        id: COMPANY_GROUP_SEARCH,
        icon: "search",
        title: "Buscar",
        modalParms: {
          advanced: true,
          onSelect: companyGroup => {
            this.props.companyGroupsOpenAdd(companyGroup);
            this.setOption(null);
          }
        },
        Modal: ModalCompanyGroupSelect
      },
      {
        id: COMPANY_COMANY_ADD,
        icon: "plus",
        title: "Empresa",
        modalParms: {
          onSuccess: result => {
            this.setOption(null);
          }
        },
        Modal: ModalCompanyAdd
      },
      {
        id: SCHEDULE,
        icon: "calendar-alt",
        title: "Agenda"
      }
    ];
  }

  setOption = id => this.setState({ currentOption: id });

  render() {
    const {
      isActive,
      canEdit,
      editMode,
      current,
      isFindLoading,
      isMapMarkersLoading,
      updateIsLoading,
      updateIsSuccess,
      updateError,
      company,
      companyGroupsOpen,
      classes,
      markers,
      update,
      setCurrent,
      companyFind,
      persons,
      clearCompany,
      onSuccess,
      clearError,
      companyGroupsOpenDelete,
      toggleEdit
    } = this.props;
    const { currentOption } = this.state;

    return !isActive ? (
      <React.Fragment />
    ) : (
      <div className="tmk">
        <Actions
          company={company}
          currentOption={currentOption}
          companyGroupsOpen={companyGroupsOpen}
          options={this.options}
          classes={classes}
          setOption={this.setOption}
          companyFind={companyFind}
          clearCompany={clearCompany}
          onSuccess={this.onSuccess}
          companyGroupsOpenDelete={companyGroupsOpenDelete}
        />
        {(isFindLoading || company) && (
          <div className="tmk-back">
            <CompanyInfo
              canEdit={canEdit}
              editMode={editMode}
              isLoading={isFindLoading}
              company={company}
              persons={persons}
              setCurrent={setCurrent}
              toggleEdit={toggleEdit}
            />
            <Images
              isLoading={isFindLoading}
              items={company ? company.images : []}
            />
            <CompanyMap isLoading={isMapMarkersLoading} markers={markers} />
            <Communications companyId={company ? company.id : null} />
            {company && current !== null && (
              <ModalObjectEdit
                isOpen={current !== null}
                isLoading={updateIsLoading}
                isSuccess={updateIsSuccess}
                rounded
                error={updateError}
                object={current}
                onClose={() => setCurrent(null)}
                onSuccess={() => companyFind(company.id)}
                submitFunc={update}
                clearError={clearError}
              />
            )}
          </div>
        )}
        {currentOption === SCHEDULE && (
          <div className="tmk-back">
            <Schedule />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Telemarketing);
