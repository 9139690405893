import React from "react";
import { Schedule as ScheduleBase } from "base";
import Render from "./render";

class Schedule extends React.Component {
  render() {
    return (
      <ScheduleBase>
        <Render {...this.props} />
      </ScheduleBase>
    );
  }
}
export default Schedule;
