import React from "react";
import { Communications as CommunicationsBase } from "base";
import Render from "./render";

class Communications extends React.Component {
  render() {
    return (
      <CommunicationsBase {...this.props}>
        <Render {...this.props} />
      </CommunicationsBase>
    );
  }
}
export default Communications;
