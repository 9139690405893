import React from "react";
import { Container, Row, Col, Modal, ModalBody } from "reactstrap";
import { IconButton } from "@material-ui/core";

import { commTypesGet } from "base";

import Icon from "../../Icon";
import "./style.css";

class ModalCommTypeSelect extends React.Component {
  state = { newIsOpen: false };

  render() {
    const { onClose, onSelect } = this.props;

    return (
      <Modal isOpen={true} className="card rounded" size="xs">
        <div className="modal-top-title">
          Seleccione el tipo de comunicación
        </div>
        <IconButton className="modal-top-close" onClick={onClose}>
          <Icon>times</Icon>
        </IconButton>
        <ModalBody className="no-padding">
          <Container className="list">
            {commTypesGet().map(({ id, name, icon }, i) => {
              return (
                <Row key={i} onClick={() => onSelect(id)}>
                  <Col xs="2" className="text-center">
                    <Icon>{icon}</Icon>
                  </Col>
                  <Col>{name}</Col>
                </Row>
              );
            })}
          </Container>
        </ModalBody>
      </Modal>
    );
  }
}

export default ModalCommTypeSelect;
