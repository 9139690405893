import { configId } from "base";

// Import ---------------------------------------

export const assetsFolders = {
  img: {
    brands: `img/brands/${configId.toLowerCase()}`,
    payments: "img/payments"
  }
};

export const assetsImport = (folder, file) => {
  return require(`../../assets/${folder}/${file}`);
};

// Category -------------------------------------

export const categoryGetItems = (type, item) => {
  return {
    id: item.id,
    name: item.name,
    link: type === "b" ? "/productos?b=all" : `/productos?${type}=${item.id}`,
    items: !item.items
      ? []
      : [
          ...item.items.map(subItem => {
            return {
              id: subItem.id,
              name: subItem.name,
              link: `/productos?${type}=${subItem.id}`
            };
          })
        ]
  };
};

// Image ----------------------------------------

export const imageGet = (code, images) =>
  images && images.length > 0
    ? images[0].image
    : `http://www.robotec.com.uy/Catalogo_JPG/${code}.jpg`;
