import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Button from "@material-ui/core/Button";

import { numberTypes, numberFormat } from "base";

import { imageGet } from "../../helpers";

import Icon from "../Icon";
import Stock from "../Stock";

import "./style.css";

class ProductCard extends React.Component {
  componentDidMount() {
    if (!this.props.hideActions) {
      this.props.getStock(this.props.product.code);
    }
  }

  render() {
    const {
      hidePrice,
      hideActions,
      stockLoading,
      isSeller,
      stockQuant,
      size,
      product,
      cartAddItem,
      setCurrent
    } = this.props;

    const {
      isLoading,
      isPublic,
      isOriginal,
      code,
      name,
      price,
      images
    } = product;
    const img = imageGet(code, images);

    const style = false ? {} : { backgroundImage: `url(${img})` };
    return (
      <div className={`product-card ${size}`}>
        {isSeller && (
          <div className="product-admin-actions absolute text-right">
            <button onClick={() => setCurrent(product)}>
              Editar<Icon>edit</Icon>
            </button>
          </div>
        )}
        <Link to={`/productos/${product.code}`}>
          <div className="card">
            <Row noGutters>
              <Col>
                <div className="product-card-img" style={style} />
              </Col>
            </Row>
            <div className="product-card-body">
              <div
                className={`product-card-title ${isLoading ? "loading" : ""}`}
              >
                {isLoading ? "" : name}
              </div>

              {!hidePrice && (
                <div
                  className={`product-card-detail ${
                    isLoading ? "loading" : ""
                  }`}
                >
                  {isLoading ? (
                    ""
                  ) : (
                    <Row noGutters>
                      <Col xs="5" className="product-card-code">
                        <small>Cod</small> {code}
                      </Col>
                      <Col xs="7" className="product-card-price">
                        <small>U$S</small>{" "}
                        {numberFormat(numberTypes.PRICE, price)}
                      </Col>
                    </Row>
                  )}
                </div>
              )}
              {!hideActions && (
                <Row className="product-card-actions">
                  <Col xs="auto">
                    <div className="product-card-stock">
                      <Stock
                        isLoading={stockLoading}
                        isSeller={isSeller}
                        quant={stockQuant}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </div>
            {!isLoading && (
              <div className="tags">
                {isSeller && !isPublic && (
                  <div className="tag inactive">Inactivo</div>
                )}
                {isOriginal && <div className="tag original">Original</div>}
              </div>
            )}
          </div>
        </Link>
        {!hideActions && (
          <Row className="product-card-actions">
            <Col />
            <Col
              className={`text-right btn-add ${
                stockQuant <= 0 ? "disabled" : ""
              }`}
            >
              <Button
                size="small"
                disabled={stockQuant <= 0}
                onClick={event => {
                  event.stopPropagation();
                  cartAddItem(product);
                }}
              >
                Comprar <Icon>shopping-bag</Icon>
              </Button>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default withRouter(ProductCard);
