import React from "react";
import { Container, Row, Col } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import {
  TYPE_TEXT,
  TYPE_CLIENT_OF,
  TYPE_TAG,
  TYPE_WORK_BRANCH,
  TYPE_CATEGORY,
  filterGetFromType
} from "base";

import Icon from "../Icon";
import ModalFiltersLists from "../ModalFiltersLists";

import "./style.css";

class Search extends React.Component {
  state = { isFiltersOpen: false, value: "" };

  onInputChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onSubmit = event => {
    event.preventDefault();
    const { zeroLength } = this.props;
    const { value } = this.state;
    if (zeroLength || value.length > 1) {
      const { link, history } = this.props;
      if (link) history.push(`${link}?t=${value}`);
      else this.props.setFilter({ type: TYPE_TEXT, value });
    }
  };

  load = () => {
    const filter = filterGetFromType(this.props.filters, TYPE_TEXT);
    if (filter && filter.current) {
      this.setState({ value: filter.current.value });
    }
  };

  componentDidMount() {
    this.load();
  }

  render() {
    const {
      autoComplete,
      rounded,
      showFilter,
      paddingTop
      // filters
    } = this.props;
    const { isFiltersOpen, value } = this.state;
    return (
      <div className={`search ${paddingTop ? "pt" : ""}`}>
        <form noValidate onSubmit={this.onSubmit}>
          <Container>
            <Row>
              <Col>
                <TextField
                  fullWidth
                  type="text"
                  name="value"
                  className="form-control"
                  label="Buscar"
                  value={value}
                  InputProps={{
                    autoComplete: autoComplete ? "on" : "off",
                    disableUnderline: true
                  }}
                  onChange={this.onInputChange}
                />
              </Col>
              {showFilter && (
                <Col xs="auto">
                  <Button
                    size="large"
                    onClick={() => this.setState({ isFiltersOpen: true })}
                  >
                    <Icon>filter</Icon>
                  </Button>
                </Col>
              )}
              <Col xs="auto">
                <Button type="submit" size="large">
                  <Icon>search</Icon>
                </Button>
              </Col>
            </Row>
          </Container>
        </form>
        {isFiltersOpen && (
          <ModalFiltersLists
            notClearOnClose
            rounded={rounded}
            visibleFilters={[
              TYPE_CLIENT_OF,
              TYPE_TAG,
              TYPE_WORK_BRANCH,
              TYPE_CATEGORY
            ]}
            onClose={() => this.setState({ isFiltersOpen: false })}
          />
        )}
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.filtersLoaded && this.props.filtersLoaded) {
      this.load();
    }
  }
}

Search.defaultProps = {
  autoComplete: true,
  showFilter: false
};

export default Search;
