import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Tabs, Tab, Avatar, Button } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";

import { stringFirstLetter, pesonGetColor, numbersOnly, objects } from "base";

import ObjectSection from "../../ObjectSection";
import Divider from "../../Divider";
import Loading from "../../Loading";
import Card from "../Card";
import Icon from "../../Icon";

const Employee = props => {
  const { employee } = props;
  const {
    isLoading,
    id,
    name,
    lastName,
    employeePositionName,
    defaultPhone,
    defaultEmail
  } = employee;
  return (
    <Row className="tmk-employee">
      <Col xs="auto" className="avatar">
        <span style={{ paddingLeft: 0 }}>
          <Avatar
            className="avatar"
            style={{ backgroundColor: pesonGetColor(id) }}
          >
            {stringFirstLetter(name)}
          </Avatar>
        </span>
      </Col>
      <Col xs="5">
        <span>
          {`${name} ${lastName}`} <br />
          <span className="tmk-person-obs">{employeePositionName}</span>
        </span>
      </Col>
      <Col xs="5">
        {isLoading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <Row>
              <Col>
                <a href={"tel:" + numbersOnly(defaultPhone)}>{defaultPhone}</a>
              </Col>
            </Row>
            <Row>
              <Col>
                <a
                  href={"mailto:" + defaultEmail.trim()}
                  // style={{ paddingRight: 22 }}
                >
                  {defaultEmail.trim()}
                </a>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Col>
    </Row>
  );
};

const EmployeeSection = props => {
  const { editMode, company, setCurrent } = props;
  return (
    <div className="object-section p">
      <Container className="list">
        {company.employees.map((e, i) => (
          <Employee key={i} employee={e} />
        ))}
        {editMode && (
          <Row
            noGutters
            onClick={() =>
              setCurrent({
                id: company.id,
                isList: true,
                type: objects.companyEmployee.type,
                isAdd: true
              })
            }
          >
            <Col>
              <Icon>plus</Icon> Nuevo
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

class CompanyInfo extends React.Component {
  state = {
    tabIndex: 0
  };

  handleChangeTab = (event, tabIndex) => this.setState({ tabIndex });

  handleChangeTabIndex = tabIndex => this.setState({ tabIndex });

  render() {
    const {
      isLoading,
      canEdit,
      editMode,
      company,
      setCurrent,
      toggleEdit
    } = this.props;
    const { tabIndex } = this.state;
    return (
      <Card
        id="tmk-info"
        title={
          <React.Fragment>
            Info{" "}
            <Button onClick={canEdit ? toggleEdit : null}>
              <Icon>{editMode ? "lock-open" : "lock"}</Icon>
            </Button>
          </React.Fragment>
        }
      >
        {isLoading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <ObjectSection
              edit={editMode}
              padding={false}
              id={company.id}
              type={objects.companyName.type}
              items={[
                {
                  fontSize: "h2",
                  value: company.name,
                  parms: company
                }
              ]}
              setCurrent={setCurrent}
            />
            <Divider min marginBottom={false} />
            {(editMode || company.description) && (
              <>
                <ObjectSection
                  edit={editMode}
                  padding={false}
                  id={company.id}
                  type={objects.companyDescription.type}
                  items={[
                    {
                      label: editMode ? "Descripción" : "",
                      value: company.description,
                      parms: company
                    }
                  ]}
                  setCurrent={setCurrent}
                />
                <Divider min marginBottom={false} />
              </>
            )}
            <ObjectSection
              edit={editMode}
              padding={false}
              id={company.id}
              type={objects.companyIdentifier.type}
              items={[
                {
                  label: "RUT",
                  value: company.identifier,
                  parms: company
                }
              ]}
              setCurrent={setCurrent}
            />
            <Divider min />
            <Tabs
              value={tabIndex}
              onChange={this.handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
            >
              <Tab label="Teléfonos" />
              <Tab label="Direcciones" />
              <Tab label="Emails" />
              <Tab label="Empleados" />
              <Tab label="Listas" />
            </Tabs>
            <SwipeableViews
              index={tabIndex}
              onChangeIndex={this.handleChangeTabIndex}
            >
              <ObjectSection
                edit={editMode}
                isList
                padding={false}
                id={company.id}
                type={objects.companyPhone.type}
                items={company.phones.map(p => {
                  return {
                    isDefault: p.isDefault,
                    label: "",
                    value: p.phone,
                    parms: p
                  };
                })}
                setCurrent={setCurrent}
              />
              <ObjectSection
                edit={editMode}
                isList
                padding={false}
                id={company.id}
                type={objects.companyAddress.type}
                items={company.addresses.map(a => {
                  return {
                    isDefault: a.isDefault,
                    label: "",
                    value: `${a.address}, ${a.cityName}, ${a.stateName}`,
                    parms: a
                  };
                })}
                setCurrent={setCurrent}
              />
              <ObjectSection
                edit={editMode}
                isList
                padding={false}
                id={company.id}
                type={objects.companyEmail.type}
                items={company.emails.map(e => {
                  return {
                    isDefault: e.isDefault,
                    label: "",
                    value: e.email,
                    parms: e
                  };
                })}
                setCurrent={setCurrent}
              />
              <EmployeeSection
                editMode={editMode}
                company={company}
                setCurrent={setCurrent}
              />
              <div className="scroll">
                <ObjectSection
                  edit={editMode}
                  isList
                  title="Cliente de"
                  type="COMPANY_CLIENT_OF"
                  items={company.clientOf.map(c => {
                    return {
                      isDefault: c.isDefault,
                      label: "",
                      value: c.companyName,
                      parms: c
                    };
                  })}
                  setCurrent={setCurrent}
                />
                <ObjectSection
                  edit={editMode}
                  isList
                  title="Rubros"
                  type="COMPANY_WORK_BRANCH"
                  items={company.workBranches.map(w => {
                    return {
                      isDefault: w.isDefault,
                      label: "",
                      value: w.name,
                      parms: w
                    };
                  })}
                  setCurrent={setCurrent}
                />
                <ObjectSection
                  edit={editMode}
                  isList
                  title="Etiquetas"
                  type="COMPANY_TAGAS"
                  items={company.tags.map(t => {
                    return {
                      isDefault: t.isDefault,
                      label: "",
                      value: t.name,
                      parms: t
                    };
                  })}
                  setCurrent={setCurrent}
                />
                <ObjectSection
                  edit={editMode}
                  isList
                  title="Categorias"
                  type="COMPANY_CATEGORIES"
                  items={company.categories.map(c => {
                    return {
                      isDefault: c.isDefault,
                      label: "",
                      value: c.name,
                      parms: c
                    };
                  })}
                  setCurrent={setCurrent}
                />
              </div>
            </SwipeableViews>
          </React.Fragment>
        )}
      </Card>
    );
  }
}

export default CompanyInfo;
