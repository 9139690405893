import React from "react";
import ModalObjectEdit from "../../ModalObjectEdit";
import ModalCommTypeSelect from "../ModalCommTypeSelect";

import { objects } from "base";

class ModalCommAdd extends React.Component {
  state = { type: null };

  render() {
    const {
      isLoading,
      isSuccess,
      companyId,
      error,
      user,
      add,
      onSuccess,
      onClose
    } = this.props;
    const { type } = this.state;

    return (
      <div className="modal-object-edit">
        {!type ? (
          <ModalCommTypeSelect
            isOpen={true}
            onClose={onClose}
            onSelect={type => this.setState({ type })}
          />
        ) : (
          <ModalObjectEdit
            isOpen={true}
            rounded={true}
            isLoading={isLoading}
            isSuccess={isSuccess}
            title="Nueva comunicación"
            error={error}
            size="lg"
            object={{
              type: objects.comm.type,
              isAdd: true,
              parms: {
                type,
                creatorPersonId: user.person.id,
                creatorCompanyId: user.person.employeeOf[0].id,
                contactPersonId: 0, // null
                contactCompanyId: companyId
              }
            }}
            submitFunc={add}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        )}
      </div>
    );
  }
}

export default ModalCommAdd;
