import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Button } from "@material-ui/core";
import dateUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";

import {
  dateFormats,
  dateFormat,
  objectCompareByDate,
  commGetTypeIcon,
  commGetEventTypeName
} from "base";

import Loading from "../../Loading";
import Card from "../Card";
import Icon from "../../Icon";

import "./style.css";
import ModalComm from "../ModalComm";

const DFD = dateFormats.D_MM_YYYY;
const DFH = dateFormats.hh_mm;

class Schedule extends React.Component {
  render() {
    const {
      isLoading,
      startDate,
      endDate,
      current,
      comms,
      setDate,
      filter,
      setCurrent
    } = this.props;
    return (
      <Card id="tmk-schedule" title="Agenda">
        <MuiPickersUtilsProvider utils={dateUtils} locale={esLocale}>
          <Container>
            <Row>
              <Col xs="auto">
                <DatePicker
                  disabled={isLoading}
                  label="Inicio"
                  format="dd/MM/yyyy"
                  value={startDate}
                  onChange={date => setDate("startDate", date)}
                />
              </Col>
              <Col xs="auto">
                <DatePicker
                  disabled={isLoading}
                  label="Fin"
                  format="dd/MM/yyyy"
                  minDate={startDate}
                  value={endDate}
                  onChange={date => setDate("endDate", date)}
                />
              </Col>
              <Col xs="auto">
                <Button id="btn-filter" disabled={isLoading} onClick={filter}>
                  Filtrar
                </Button>
              </Col>
            </Row>
            {isLoading ? (
              <Loading />
            ) : (
              <React.Fragment>
                {comms.length === 0 ? (
                  <Row>
                    <Col className="text-center message">
                      <h6>No se encontraron resultados.</h6>
                    </Col>
                  </Row>
                ) : (
                  <div className="list">
                    {comms.sort(objectCompareByDate).map((comm, i) => {
                      const {
                        date,
                        type,
                        subject,
                        lastEvent,
                        contactCompanyName
                      } = comm;
                      const typeIcon = commGetTypeIcon(type);
                      return (
                        <Row
                          key={i}
                          className="comm-row"
                          onClick={() => setCurrent(comm)}
                        >
                          <Col xs="auto">
                            <small>
                              <Icon>{typeIcon}</Icon>
                            </small>
                          </Col>
                          <Col xs="2">{dateFormat(DFD, date)}</Col>
                          <Col xs="1">{dateFormat(DFH, date)}</Col>
                          <Col xs="3">{contactCompanyName}</Col>
                          <Col xs="4">{subject}</Col>
                          <Col>{commGetEventTypeName(lastEvent)}</Col>
                        </Row>
                      );
                    })}
                  </div>
                )}
              </React.Fragment>
            )}
          </Container>
        </MuiPickersUtilsProvider>
        {current && (
          <ModalComm comm={current} onClose={() => setCurrent(null)} />
        )}
      </Card>
    );
  }
}

export default Schedule;
