import React from "react";
import { Container, Row, Col } from "reactstrap";
import Swiper from "react-id-swiper";

import { configImgBtnCarousel } from "base";

import ProductCard from "../../components/ProductCard";
import Divider from "../../components/Divider";

import "react-id-swiper/src/styles/css/swiper.css";
import "./style.css";

const add = 21;

const swiperParms = {
  loop: false,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  }
};

if (configImgBtnCarousel.prev) {
  swiperParms.renderPrevButton = () => (
    <button
      className="swiper-button-prev"
      style={{ backgroundImage: `url(${configImgBtnCarousel.prev})` }}
    />
  );
}
if (configImgBtnCarousel.next) {
  swiperParms.renderNextButton = () => (
    <button
      className="swiper-button-next"
      style={{ backgroundImage: `url(${configImgBtnCarousel.next})` }}
    />
  );
}

class ProductCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { fullWidth, paddingLeft, windowSize, items } = this.props;
    const slidesPerView = fullWidth ? 7 : 4;
    const breakpoints = fullWidth
      ? {
          [1800 + add]: { slidesPerView: 7, slidesPerGroup: 7 },
          [1600 + add]: { slidesPerView: 6, slidesPerGroup: 6 },
          [1400 + add]: { slidesPerView: 5, slidesPerGroup: 5 },
          [1200 + add]: { slidesPerView: 4, slidesPerGroup: 4 },
          [1024 + add]: { slidesPerView: 4, slidesPerGroup: 4 },
          [768 + add]: { slidesPerView: 4, slidesPerGroup: 4 },
          [540 + add]: { slidesPerView: 2, slidesPerGroup: 2 },
          [320 + add]: { slidesPerView: 2, slidesPerGroup: 2 }
        }
      : {
          [1024 + add]: { slidesPerView: 3, slidesPerGroup: 3 },
          [768 + add]: { slidesPerView: 3, slidesPerGroup: 3 },
          [540 + add]: { slidesPerView: 2, slidesPerGroup: 2 },
          [320 + add]: { slidesPerView: 2, slidesPerGroup: 2 }
        };
    return (
      <div
        className={`product-carousel ${fullWidth ? "full-width" : ""} ${
          paddingLeft ? "pl" : ""
        } ${windowSize}`}
      >
        {items.map((rl, i) => (
          <div key={i} className="product-carousel-list">
            <Divider />
            <Container>
              <Row noGutters>
                <Col>
                  <div className="product-carousel-title">{rl.title}</div>
                </Col>
              </Row>
              <Row noGutters>
                <Col>
                  <div key={i} className="product-carousel-items">
                    <Swiper
                      {...swiperParms}
                      slidesPerView={slidesPerView}
                      slidesPerGroup={slidesPerView}
                      breakpoints={breakpoints}
                    >
                      {rl.items.map((item, i) => {
                        return (
                          <div key={i} className="product-carousel-item">
                            <ProductCard
                              hideActions={true}
                              size={windowSize}
                              product={item}
                            />
                          </div>
                        );
                      })}
                    </Swiper>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        ))}
      </div>
    );
  }
}

export default ProductCarousel;
