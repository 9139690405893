import React from "react";
import { CommAdd as CommAddBase } from "base";
import Render from "./render";

class ModalCommAdd extends React.Component {
  render() {
    return (
      <CommAddBase>
        <Render {...this.props} />
      </CommAddBase>
    );
  }
}
export default ModalCommAdd;
