import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import queryString from "query-string";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";

import { numberFormat, numberTypes, PayPal } from "base";
import { assetsFolders, assetsImport } from "../../helpers";

import MasterPage from "../MasterPage";
import Icon from "../../components/Icon";
import CartTable from "../../components/CartTable";
import Divider from "../../components/Divider";
import Loading from "../../components/Loading";
import Snackbar from "../../components/Snackbar";

import img_card_visa from "../../../assets/img/credit-cards/card-visa.svg";
import img_card_master from "../../../assets/img/credit-cards/card-master.svg";
import img_card_amex from "../../../assets/img/credit-cards/card-amex.svg";
import img_card_discover from "../../../assets/img/credit-cards/card-discover.svg";

import "./style.css";

// MUI
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#43a047"
    }
  },
  typography: {
    useNextVariants: true
  }
});

const PersonalData = props => {
  const { user, currentPhone, currentAddress } = props;
  const { email, person } = user;
  const { name, lastName } = person;
  const phone = currentPhone;
  const address = currentAddress;
  const colLblXS = 3;
  return (
    <div className="object-section data">
      <span className="title">Datos personales</span>
      <Link to="/perfil">
        <Button size="small" variant="outlined" className="btn-edit">
          Editar <Icon>edit</Icon>
        </Button>
      </Link>
      <Container>
        <Row>
          <Col sm="12" lg="5">
            <Row>
              <Col xs={colLblXS}>
                <label>Nombre</label>
              </Col>
              <Col>
                <span>{!name ? "-" : `${name} ${lastName}`}</span>
              </Col>
            </Row>
            <Row>
              <Col xs={colLblXS}>
                <label>Email</label>
              </Col>
              <Col>
                <span>{email}</span>
              </Col>
            </Row>
          </Col>
          <Col sm="12" lg="7">
            <Row>
              <Col xs={colLblXS}>
                <label>Teléfono</label>
              </Col>
              <Col>
                <span>{!phone ? "-" : phone.phone}</span>
              </Col>
            </Row>
            <Row>
              <Col xs={colLblXS}>
                <label>Dirección</label>
              </Col>
              <Col>
                <span>
                  {!address
                    ? "-"
                    : `${address.address}, ${address.cityName}, ${
                        address.stateName
                      }`}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const Shipping = props => {
  const { currentShipping, shippings, setShipping } = props;
  return (
    currentShipping && (
      <div className="object-section">
        <span className="title">Envio / Retiro</span>
        <Container>
          <Row className="text-center">
            {shippings.map((s, i) => {
              return (
                <Col key={i} xs="12" md="5" lg="3">
                  <div
                    className={`radio-item ${
                      currentShipping.id === s.id ? "active" : ""
                    }`}
                    onClick={() => setShipping(s.id)}
                  >
                    <Radio
                      checked={currentShipping.id === s.id}
                      value={s.id}
                      color="primary"
                    />
                    {s.name}
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col>
              <div className="text-center shipping-desc">
                <br />
                {currentShipping.description}{" "}
                {currentShipping.price === 0 ? (
                  <small>(Sin costo)</small>
                ) : (
                  <small>
                    (Costo U$S{" "}
                    {numberFormat(numberTypes.PRICE, currentShipping.price)})
                  </small>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  );
};

const Payment = props => {
  const { currentPayment, payments, setPayment } = props;
  return (
    currentPayment && (
      <div className="object-section payment">
        <span className="title">Metodo de pago</span>
        <Container>
          <Row className="text-center">
            {payments.map((p, i) => {
              let img;
              try {
                img = assetsImport(
                  assetsFolders.img.payments,
                  `${p.id.toLowerCase()}.png`
                );
              } catch (ex) {}
              return (
                <Col key={i} sm="12" md="4">
                  <div
                    className={`radio-item img ${
                      currentPayment.id === p.id ? "active" : ""
                    }`}
                    onClick={() => setPayment(p.id)}
                  >
                    <Radio
                      checked={currentPayment.id === p.id}
                      value={p.id}
                      color="primary"
                    />
                    <img src={img} alt={p.name} />
                  </div>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col>
              <br />
              <PaymentDescription currentPayment={currentPayment} />
            </Col>
          </Row>
        </Container>
      </div>
    )
  );
};

const PaymentDescription = props => {
  const { currentPayment } = props;
  return (
    <div className="payment-desc text-center">
      {currentPayment.id === "PYPL" && (
        <span>
          Continuarás el proceso en la página de{" "}
          <a
            href="https://www.paypal.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            PayPal
          </a>{" "}
          donde se efectuará el pago.
          <br />
          <img src={img_card_visa} alt="Visa" />
          <img src={img_card_master} alt="Master Card" />
          <img src={img_card_amex} alt="AMEX" />
          <img src={img_card_discover} alt="Discover" />
        </span>
      )}
      {currentPayment.id === "BRDE" && (
        <span>
          Debes depositar a nombre de
          <br />
          <h6>
            SIRCAL S.A.
            <br />
            Cuenta Corriente Dólares 001562397-00001
            <br />
            Cuenta Corriente Pesos 001562397-00002
          </h6>
        </span>
      )}
      {currentPayment.id === "ABGI" && (
        <span>
          Debes girar a nombre de <h6>Maria Fernanda Varela CI 1977433-7</h6>
        </span>
      )}
    </div>
  );
};

const Total = props => {
  const { cartItemsCount, cartTotal, shippingPrice } = props;
  return (
    <div className="object-section total">
      <Container>
        <Row>
          <Col>
            Envio
            <br />
            <span className="total-item min">
              <small>U$S</small>{" "}
              {numberFormat(numberTypes.PRICE, shippingPrice)}
            </span>
          </Col>
          <Col>
            {cartItemsCount} Items
            <br />
            <span className="total-item min">
              <small>U$S</small> {numberFormat(numberTypes.PRICE, cartTotal)}
            </span>
          </Col>
          <Col xs={6}>
            Total
            <br />
            <span className="total-item">
              <small>U$S</small>{" "}
              {numberFormat(numberTypes.PRICE, shippingPrice + cartTotal)}
            </span>{" "}
            <small>IVA inc</small>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const Actions = props => {
  const {
    cartItemsCount,
    showDetail,
    currentPayment,
    setShowDetail,
    createOrder,
    onConfirm
  } = props;
  return (
    currentPayment && (
      <div className="cart-actions">
        {!showDetail ? (
          <Button
            variant="contained"
            size="large"
            color="primary"
            disabled={cartItemsCount === 0}
            onClick={setShowDetail}
          >
            Finalizar compra
          </Button>
        ) : (
          <div>
            {currentPayment.id === "PYPL" ? (
              cartItemsCount > 0 && (
                <div id="paypal-button-container">
                  <PayPal.ExpressButton createOrder={createOrder} />
                </div>
              )
            ) : (
              <MuiThemeProvider theme={theme}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={cartItemsCount === 0}
                  onClick={onConfirm}
                >
                  Confirmar compra
                </Button>
              </MuiThemeProvider>
            )}
          </div>
        )}
      </div>
    )
  );
};

class CartPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetail: false,
      messages: []
    };
  }

  addMessage = (type, message) => {
    let { messages } = this.state;
    messages.push(<Snackbar type={type} message={message} />);
    this.setState({ messages });
  };

  setShowDetail = () => {
    const { userIsAuthenticated } = this.props;
    const { showDetail } = this.state;
    if (userIsAuthenticated) this.setState({ showDetail: !showDetail });
    else this.props.history.push("/iniciar/?from=/mi-compra?detail");
  };

  componentDidMount() {
    document.title = "Mi compra";
    //
    const { search } = this.props.location;
    const urlQuery = queryString.parse(search);
    if (urlQuery.detail !== undefined) this.setShowDetail();
  }

  render() {
    const {
      userIsAuthenticated,
      isOrderAddLoading,
      isOrderAddSuccess,
      cartItemsCount,
      cartTotal,
      currentPhone,
      currentAddress,
      currentPayment,
      currentShipping,
      payments,
      shippings,
      user,
      setPayment,
      setShipping,
      createOrder,
      onConfirm
    } = this.props;
    const { showDetail, messages } = this.state;

    return (
      <MasterPage headerProps={{ showCartDrawer: false }}>
        <div className="cart-page">
          <Container>
            <Row>
              <Col>
                <div className="title">MI COMPRA</div>
              </Col>
            </Row>
          </Container>
          <Divider />
          <Container>
            <Row>
              <Col>
                {isOrderAddLoading ? (
                  <Loading />
                ) : (
                  <div className="card">
                    {isOrderAddSuccess ? (
                      <div className="object-section result text-center">
                        <Icon>check-circle</Icon>
                        <br />
                        ¡El pedido fue ingresado correctamente!
                        <br />
                        En breve recibirás mas información.
                      </div>
                    ) : (
                      <div>
                        {/* Details */}
                        {showDetail && (
                          <div>
                            <Row>
                              <Col>
                                {userIsAuthenticated && (
                                  <PersonalData
                                    user={user}
                                    currentPhone={currentPhone}
                                    currentAddress={currentAddress}
                                  />
                                )}
                              </Col>
                            </Row>
                            <Divider marginBottom={false} />
                            <Row>
                              <Col>
                                <Shipping
                                  currentShipping={currentShipping}
                                  shippings={shippings}
                                  setShipping={setShipping}
                                />
                              </Col>
                            </Row>
                            <Divider marginBottom={false} />
                            <Row>
                              <Col>
                                <Payment
                                  currentPayment={currentPayment}
                                  payments={payments}
                                  setPayment={setPayment}
                                />
                              </Col>
                            </Row>
                            <Divider marginBottom={false} />
                          </div>
                        )}
                        {/* Footer */}
                        <div>
                          <Row>
                            <Col className="text-center">
                              <Total
                                cartItemsCount={cartItemsCount}
                                cartTotal={cartTotal}
                                shippingPrice={
                                  currentShipping ? currentShipping.price : 0
                                }
                              />
                            </Col>
                            <Col xs="12" lg="6" className="text-center">
                              <Actions
                                showDetail={showDetail}
                                cartItemsCount={cartItemsCount}
                                currentPayment={currentPayment}
                                setShowDetail={this.setShowDetail}
                                createOrder={createOrder}
                                onConfirm={onConfirm}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </Container>
          <Divider marginTop />
          <Container>
            <Row>
              <Col>
                {!isOrderAddLoading && (
                  <div className="card">
                    <CartTable showPrice={true} />
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
        {messages.map((message, i) => {
          return <div key={i}>{message}</div>;
        })}
      </MasterPage>
    );
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.userIsAuthenticated && !this.props.userIsAuthenticated) {
      this.setState({ showDetail: false });
      this.props.history.push("/mi-compra");
    }
    if (
      this.props.orderAddError !== "" &&
      prevProps.orderAddError !== this.props.orderAddError
    ) {
      this.addMessage("error", this.props.orderAddError);
    }
    const prevErrKeys = Object.keys(prevProps.errors);
    const propErrKeys = Object.keys(this.props.errors);

    if (prevErrKeys.length === 0 && propErrKeys.length > 0) {
      propErrKeys.map(k => this.addMessage("error", this.props.errors[k]));
      this.props.clearErrors();
    }
  }
}

export default CartPage;
