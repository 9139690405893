import React from "react";
import { CompanyGroupAdd as CompanyGroupAddBase } from "base";
import Render from "./render";

class ModalCompanyGroupAdd extends React.Component {
  render() {
    return (
      <CompanyGroupAddBase {...this.props}>
        <Render {...this.props} />
      </CompanyGroupAddBase>
    );
  }
}
export default ModalCompanyGroupAdd;
