import React from "react";

import "./style.css";

import Header from "../../components/Header";
import Banner from "../../components/Banner";

class PrintPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = "Robotec Academy";
  }

  render() {
    return (
      <div className="user-page user-page-login">
        <Header minimal transparent />
        <Banner />
        <div className="section">AAA</div>
      </div>
    );
  }
}

export default PrintPage;
