import React from "react";
import { Container, Row, Col, Modal, ModalBody, ModalFooter } from "reactstrap";
import { IconButton, Button } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import dateUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

import {
  commEventTypes,
  dateFormats,
  dateFormat,
  commGetEventTypeName
} from "base";

import Icon from "../../Icon";
import Comm from "../Comm";

import "./style.css";

const DFDH = dateFormats.DD_MM_YYYY__hh_mm;

const ModalEvents = props => {
  const { isOpen, events, onClose } = props;
  return (
    <Modal isOpen={isOpen} className="card rounded modal-comm-events" size="xl">
      <IconButton className="modal-top-close" onClick={onClose}>
        <Icon>times</Icon>
      </IconButton>
      <ModalBody className="no-padding">
        <Container>
          <Row>
            <Col xs="2">Fecha</Col>
            <Col xs="2">Persona</Col>
            <Col xs="2">Evento</Col>
            <Col xs="2">Agenda</Col>
            <Col xs="2">Re-Agenda</Col>
            <Col xs="2">Observación</Col>
          </Row>
          {events.map((event, i) => {
            const {
              date,
              type,
              commOldDate,
              commNewDate,
              personName,
              personLastName,
              observation
            } = event;
            const fd = dateFormat(DFDH, date);
            const fod = dateFormat(DFDH, commOldDate);
            let fnd = dateFormat(DFDH, commNewDate);
            if (fod === fnd) fnd = "-";
            return (
              <Row key={i}>
                <Col xs="2">{fd}</Col>
                <Col xs="2">{`${personName} ${personLastName}`}</Col>
                <Col xs="2">{commGetEventTypeName(type)}</Col>
                <Col xs="2">{fod}</Col>
                <Col xs="2">{fnd}</Col>
                <Col xs="2">{observation}</Col>
              </Row>
            );
          })}
        </Container>
      </ModalBody>
    </Modal>
  );
};

class ModalComm extends React.Component {
  dpRef = React.createRef();
  state = { modalEventsIsOpen: false };

  render() {
    const { comm, onClose, onEdit, addEvent } = this.props;
    const { modalEventsIsOpen } = this.state;

    return (
      <React.Fragment>
        <Modal isOpen={true} size="lg" className="card rounded">
          <IconButton className="modal-top-close" onClick={onClose}>
            <Icon>times</Icon>
          </IconButton>
          <ModalBody className="no-padding">
            <Comm advanced notMargin comm={comm} />
          </ModalBody>
          <ModalFooter>
            <div className="modal-footer-left">
              <Button
                onClick={() => this.setState({ modalEventsIsOpen: true })}
              >
                Historico
              </Button>
            </div>
            <div className="modal-footer-right">
              <Button onClick={() => this.dpRef.current.open()}>
                Re-Agendar
              </Button>
              <Button color="primary" onClick={onEdit}>
                Editar
              </Button>
            </div>
          </ModalFooter>
          <MuiPickersUtilsProvider utils={dateUtils} locale={esLocale}>
            <DatePicker
              ref={this.dpRef}
              value={comm.date}
              minDate={new Date()}
              style={{ display: "none" }}
              onChange={date => addEvent(commEventTypes.reSchedule.id, date)}
            />
          </MuiPickersUtilsProvider>
        </Modal>
        <ModalEvents
          isOpen={modalEventsIsOpen}
          events={comm.events}
          onClose={() => this.setState({ modalEventsIsOpen: false })}
        />
      </React.Fragment>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.isSuccess === this.props.isSuccess) {
      this.props.onClose();
      this.props.onSuccess();
    }
  }
}
export default ModalComm;
