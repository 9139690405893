import React from "react";
import { CompanyAdd as CompanyAddBase } from "base";
import Render from "./render";

class ModalCompanyAdd extends React.Component {
  render() {
    return (
      <CompanyAddBase>
        <Render {...this.props} />
      </CompanyAddBase>
    );
  }
}
export default ModalCompanyAdd;
