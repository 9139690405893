import React from "react";
import { Comm as CommBase } from "base";
import Render from "./render";

class Comm extends React.Component {
  render() {
    return (
      <CommBase {...this.props}>
        <Render {...this.props} />
      </CommBase>
    );
  }
}
export default Comm;
