import React from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import Button from "@material-ui/core/Button";
import { numberTypes, numberFormat } from "base";
import Icon from "../Icon";

import CartRow from "./Row";
import "./style.css";

const CartTable = props => {
  const {
    windowSize,
    showActions,
    drawerMode,
    showPrice,
    showTotal,
    items,
    itemsCount,
    total,
    cartItemSetQuantity,
    cartItemDelete
  } = props;

  return (
    <div className={`cart-table ${drawerMode ? "drawer" : ""}`}>
      {itemsCount === 0 ? (
        <div>
          <Table>
            <tbody>
              <tr>
                {drawerMode ? (
                  <th className="cart-th-title">
                    <Icon>shopping-bag</Icon> Mi compra
                  </th>
                ) : (
                  <th className="cart-th-title">
                    {itemsCount > 0 ? "Producto" : ""}
                  </th>
                )}
              </tr>
            </tbody>
          </Table>
          <p>Su carrito se encuentra vacio.</p>
        </div>
      ) : (
        <div>
          <Table>
            <tbody>
              {drawerMode ? (
                <tr>
                  <th colSpan="5" className="cart-th-title">
                    <Icon>shopping-bag</Icon> Mi compra{" "}
                    <small>{`${itemsCount} item${
                      itemsCount > 1 ? "s" : ""
                    }`}</small>
                  </th>
                </tr>
              ) : (
                <tr>
                  <th colSpan="2">Producto</th>
                  <th className="d-none d-sm-table-cell text-center">Cant.</th>
                  {showPrice && (
                    <th className="d-none d-md-table-cell text-right">
                      Precio
                    </th>
                  )}
                  <th className="d-none d-sm-table-cell text-right">
                    Subtotal
                  </th>
                  {showActions && <th className="d-none d-sm-table-cell" />}
                </tr>
              )}

              {/* <tr className="d-sm-none d-table-row">
                <th colSpan="3" className="cart-th-title">
                  <Icon>shopping-bag</Icon> Mi compra{" "}
                  <small>({itemsCount} items)</small>
                </th>
              </tr> */}

              {items.map((item, i) => {
                return (
                  <CartRow
                    key={i}
                    showActions={showActions}
                    showPrice={showPrice}
                    item={item}
                    cartItemSetQuantity={cartItemSetQuantity}
                    cartItemDelete={cartItemDelete}
                  />
                );
              })}
              {showTotal && (
                <tr>
                  {windowSize !== "xs" && (
                    <td colSpan={windowSize === "sm" ? "2" : "3"} />
                  )}
                  <td className="cart-total text-right">Total:</td>
                  <td className="cart-total text-right">
                    <small>U$S</small> {numberFormat(numberTypes.PRICE, total)}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {drawerMode && (
            <div className="cart-footer">
              Total: <small>U$S</small> {numberFormat(numberTypes.PRICE, total)}
              <Link to="/mi-compra">
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  Finalizar compra
                </Button>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

CartTable.defaultProps = {
  showActions: true,
  drawerMode: false
};

export default CartTable;
