import React from "react";
import { Telemarketing as BaseTelemarketing } from "base";
import Render from "./render";

class Telemarketing extends React.Component {
  render() {
    return (
      <BaseTelemarketing>
        <Render {...this.props} />
      </BaseTelemarketing>
    );
  }
}
export default Telemarketing;
