import React from "react";
import { objects } from "base";
import ModalObjectEdit from "../../ModalObjectEdit";

class ModalCompanyGroupAdd extends React.Component {
  state = {};

  render() {
    const { isLoading, isSuccess, add, onClose, onSuccess, error } = this.props;
    return (
      <ModalObjectEdit
        isOpen={true}
        rounded={true}
        isLoading={isLoading}
        isSuccess={isSuccess}
        title="Nueva empresa grupo"
        error={error}
        // size="lg"
        object={{
          type: objects.companyGroup.type,
          isAdd: true,
          parms: {}
        }}
        submitFunc={add}
        onClose={onClose}
        onSuccess={onSuccess}
      />
    );
  }
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (this.props.isSuccess && this.props.addResult) {
  //     this.props.onSuccess(this.props.addResult);
  //   }
  // }
}

export default ModalCompanyGroupAdd;
