import React from "react";

const Card = props => {
  const { id, padding, title } = props;
  return (
    <div id={id} className={`card fixed`}>
      <div className="tmk-comm-title">{title}</div>
      <div className={`card-content ${padding ? "p" : ""}`}>
        {props.children}
      </div>
    </div>
  );
};

export default Card;
