import React from "react";
import { CompanyGroupList as CompanyGroupListBase } from "base";
import Render from "./render";

class CompanyGroupList extends React.Component {
  render() {
    return (
      <CompanyGroupListBase {...this.props}>
        <Render {...this.props} />
      </CompanyGroupListBase>
    );
  }
}
export default CompanyGroupList;
