import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { configGoogleMaps } from "base";

import Card from "../Card";
import Loading from "../../Loading";

const mapOptions = {
  streetViewControl: false,
  scaleControl: false,
  mapTypeControl: false,
  panControl: false,
  zoomControl: false,
  rotateControl: false,
  fullscreenControl: false,
  maxZoom: 15
};

const CompanyMap = props => {
  const { isLoading, markers } = props;

  const fitBounds = map => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(m => bounds.extend(m.geolocation));
    map.fitBounds(bounds);
  };

  return (
    <Card id="tmk-map" title="Ubicación">
      {/* {isLoading ? (
        <Loading />
      ) : (
        <LoadScript id="script-loader" googleMapsApiKey={configGoogleMaps.key}>
          <GoogleMap
            id="marker-example"
            mapContainerStyle={{ height: "250px", width: "100%" }}
            zoom={1}
            center={{ lat: 0, lng: 0 }}
            options={mapOptions}
            onLoad={fitBounds}
          >
            {markers.map((m, i) => (
              <Marker key={i} position={m.geolocation} />
            ))}
          </GoogleMap>
        </LoadScript>
      )} */}
    </Card>
  );
};

export default CompanyMap;

// import React from "react";
// import { compose, withProps } from "recompose";
// import {
//   withScriptjs,
//   withGoogleMap,
//   lifecycle,
//   GoogleMap,
//   Marker
// } from "react-google-maps";

// import { configGoogleMaps } from "base";

// import Card from "../Card";
// import Loading from "../../Loading";

// // const position = { lat: -34.88047, lng: -56.15182 };

// const mapOptions = {
//   streetViewControl: false,
//   scaleControl: false,
//   mapTypeControl: false,
//   panControl: false,
//   zoomControl: false,
//   rotateControl: false,
//   fullscreenControl: false
// };

// const adjustMap = (mapProps, map) => {
//   console.log(mapProps, map);
//   // const { google, markers } = mapProps;
//   // const bounds = new google.maps.LatLngBounds();
//   // console.log(google, markers, bounds);
//   // markers.forEach(marker => {
//   //   const { lat, lng } = marker.position;
//   //   bounds.extend(new google.maps.LatLng(lat, lng));
//   // });
//   // map.fitBounds(bounds);
//   // map.panToBounds(bounds);
// };

// const MapDefault = compose(
//   //   withProps({
//   //     googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${
//   //       configGoogleMaps.key
//   //     }`,
//   //     loadingElement: <div style={{ height: 250, borderRadius: 16 }} />,
//   //     containerElement: <div style={{ height: 250, borderRadius: 16 }} />,
//   //     mapElement: <div style={{ height: 250, borderRadius: 16 }} />
//   //   }),
//   //   lifecycle({
//   //     componentWillMount() {
//   //       this.setState({
//   //         zoomToMarkers: map => {
//   //           //console.log("Zoom to markers");
//   //           const bounds = new window.google.maps.LatLngBounds();
//   //           map.props.children.forEach(child => {
//   //             if (child.type === Marker) {
//   //               bounds.extend(
//   //                 new window.google.maps.LatLng(
//   //                   child.props.position.lat,
//   //                   child.props.position.lng
//   //                 )
//   //               );
//   //             }
//   //           });
//   //           map.fitBounds(bounds);
//   //         }
//   //       });
//   //     }
//   //   }),
//   //   withScriptjs,
//   //   withGoogleMap
//   // )(
//   withProps({
//     googleMapURL:
//       "https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places",
//     loadingElement: <div style={{ height: `100%` }} />,
//     containerElement: <div style={{ height: `400px` }} />,
//     mapElement: <div style={{ height: `100%` }} />
//   }),
//   withScriptjs,
//   withGoogleMap,
//   lifecycle({
//     componentDidMount() {
//       const DirectionsService = new google.maps.DirectionsService();

//       DirectionsService.route(
//         {
//           origin: new google.maps.LatLng(41.85073, -87.65126),
//           destination: new google.maps.LatLng(41.85258, -87.65141),
//           travelMode: google.maps.TravelMode.DRIVING
//         },
//         (result, status) => {
//           if (status === google.maps.DirectionsStatus.OK) {
//             this.setState({
//               directions: result
//             });
//           } else {
//             console.error(`error fetching directions ${result}`);
//           }
//         }
//       );
//     }
//   })
// )(
//   //
//   props => (
//     <GoogleMap
//       defaultZoom={15}
//       defaultCenter={
//         props.markers.length > 0
//           ? props.markers[0].geolocation
//           : { lat: 0, lng: 0 }
//       }
//       defaultOptions={mapOptions}
//       onIdle={adjustMap}
//     >
//       {props.markers.map((m, i) => {
//         return (
//           <Marker
//             key={i}
//             position={m.geolocation}
//             defaultAnimation={window.google.maps.Animation.DROP}
//           />
//         );
//       })}
//     </GoogleMap>
//   )
// );
