import React from "react";
import { Row, Col } from "reactstrap";
import Swiper from "react-id-swiper";

import Card from "../Card";
import Loading from "../../Loading";

const swiperImgParms = {
  slidesPerView: 3,
  spaceBetween: 170,
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  }
};

const Images = props => {
  const { isLoading, items } = props;
  return (
    <Card id="tmk-images" padding title="Fotos">
      {isLoading ? (
        <Loading />
      ) : (
        <Row noGutters>
          <Swiper {...swiperImgParms}>
            {items.map(({ image }, i) => (
              <Col key={i} xs="auto">
                <div
                  className="tmk-img"
                  style={{ backgroundImage: `url(${image})` }}
                />
              </Col>
            ))}
          </Swiper>
        </Row>
      )}
    </Card>
  );
};

export default Images;
