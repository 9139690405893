import React from "react";
import { Row, Col } from "reactstrap";

import {
  commGetTypeName,
  commGetTypeIcon,
  dateFormats,
  dateFormat,
  dateFormatLong
} from "base";

import Icon from "../../Icon";

const Comm = props => {
  const { advanced, notMargin, comm, onClick } = props;
  const {
    date,
    type,
    creatorPersonName,
    creatorPersonLastName,
    contactCompanyName,
    subject,
    message,
    lastEvent
  } = comm;
  const typeName = commGetTypeName(type);
  const typeIcon = commGetTypeIcon(type);
  const fDate = advanced
    ? dateFormatLong(date, true)
    : dateFormat(dateFormats.hh_mm, date);
  return (
    <div
      className={`tmk-comm ${advanced ? "advanced" : ""} ${
        notMargin ? "no-margin" : ""
      } ${lastEvent.toLowerCase()}`}
      onClick={onClick}
    >
      <Row>
        <Col>
          <Row noGutters className="tmk-comm-header">
            <Col className="text-left">
              <small>
                <Icon>{typeIcon}</Icon>
              </small>
              {typeName}
            </Col>
            <Col className="text-center">{fDate}</Col>
            <Col className="text-right">{`${creatorPersonName} ${creatorPersonLastName}`}</Col>
          </Row>
          {advanced && (
            <Row noGutters>
              <Col>
                {<label>EMPRESA</label>}
                <p>{contactCompanyName}</p>
              </Col>
            </Row>
          )}
          <Row noGutters>
            <Col>
              <label>ASUNTO</label>
              <p>{subject}</p>
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <label>MENSAJE</label>
              <p>
                {message.split("\n").map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      {item}
                      <br />
                    </React.Fragment>
                  );
                })}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Comm;
