import React from "react";
import { objects } from "base";

import ModalCompanyGroupSelect from "../ModalCompanyGroupSelect";
import ModalObjectEdit from "../../ModalObjectEdit";

class ModalCompanyAdd extends React.Component {
  render() {
    const {
      isLoading,
      isSuccess,
      error,
      companyGroup,
      add,
      onClose,
      onSuccess,
      setCompanyGroup
    } = this.props;

    return (
      <div className="modal-object-edit">
        {!companyGroup ? (
          <ModalCompanyGroupSelect
            rounded={true}
            isOpen={true}
            showFooter={true}
            title="Selecciona el Grupo de la Empresa"
            onClose={onClose}
            onSelect={companyGroup => setCompanyGroup(companyGroup)}
          />
        ) : (
          <ModalObjectEdit
            isOpen={true}
            rounded={true}
            isLoading={isLoading}
            isSuccess={isSuccess}
            title="Nueva empresa"
            error={error}
            object={{
              type: objects.company.type,
              isAdd: true,
              parms: {
                companyGroupId: companyGroup.id,
                companyGroupName: companyGroup.name
              }
            }}
            submitFunc={add}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        )}
      </div>
    );
  }
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (this.props.isSuccess && this.props.addResult) {
  //     this.props.onSuccess(this.props.addResult);
  //   }
  // }
}

export default ModalCompanyAdd;
