import React from "react";
import ReactDOM from "react-dom";
import { Link as LinkBase } from "react-router-dom";
import {
  Navbar,
  Container,
  Row,
  Col,
  Nav,
  NavItem as NavItemBase
} from "reactstrap";
import Drawer from "@material-ui/core/Drawer";
import Avatar from "@material-ui/core/Avatar";
import Radium, { StyleRoot } from "radium";
import {
  numberTypes,
  numberFormat,
  stringFirstLetter,
  configLogo,
  configName,
  configOtionsMenu,
  configStyle
} from "base";

import CartTable from "../CartTable";
import Icon from "../Icon";
import Divider from "../Divider";
import Search from "../Search";
import Brands from "../Brands";
import Categories from "../Categories";
import ThreeD from "../ThreeD";

import "./style.css";

const Link = Radium(LinkBase);
const NavItem = Radium(NavItemBase);
const scrollYMin = 30;

const DrawerMenu = props => {
  const { userIsAuthenticated, user, options, userLogout } = props;
  return (
    <ul className="drawer-menu">
      {options.map((o, i) => (
        <li key={i} id={`drawer-menu-${i}`}>
          {o.link.indexOf("http") !== -1 ? (
            <a key={i} style={configStyle.menu.item} href={o.link}>
              {o.name}
            </a>
          ) : (
            <Link style={configStyle.menu.item} to={o.link}>
              {o.name}
            </Link>
          )}
        </li>
      ))}
      {userIsAuthenticated ? (
        <div className="user-actions">
          <li className="user-actions-user">
            <Row noGutters>
              <Col xs="auto">
                <Avatar className="avatar" style={configStyle.secondary}>
                  {stringFirstLetter(user.firstName)}
                </Avatar>
              </Col>
              <Col>
                <div className="user-actions-title">
                  {user.firstName + " " + user.lastName}
                  <div className="user-actions-subtitle">{user.email}</div>
                </div>
              </Col>
            </Row>
          </li>
          <li>
            <Link style={configStyle.menu.item} to="/perfil">
              Perfil
            </Link>
          </li>
          <li>
            <Link style={configStyle.menu.item} to="/mis-compras">
              Mis compras
            </Link>
          </li>
          {// Seller
          user && user.isSeller && user.person.employeeOf.length > 0 && (
            <div>
              {user.person.employeeOf.map((c, i) => {
                return (
                  <div key={i}>
                    <li>
                      <Link style={configStyle.menu.item} to={`/${c.id}/pedidos`}>
                        {c.name}
                      </Link>
                    </li>
                    <li>
                      <Link style={configStyle.menu.item} to={`/${c.id}/config`}>
                        Configuración
                      </Link>
                    </li>
                  </div>
                );
              })}
            </div>
          )}
          <li className="user-actions-exit" style={configStyle.menu.item} onClick={() => userLogout()}>
            Salir
          </li>
        </div>
      ) : (
        <li>
          <div className="user-actions">
            <Link style={configStyle.menu.item} to="/iniciar">            
                <Icon>user</Icon> Ingresar            
            </Link>
          </div>
        </li>
      )}
    </ul>
  );
};

const getRect = (node, element) => {
  if (node instanceof HTMLElement) {
    const nodeEl = node.querySelector(element);
    if (nodeEl) return nodeEl.getBoundingClientRect();
  }
  return null;
};

const ArrowUp = props => {
  const { show, node, element } = props;
  const mir = getRect(node, `#${element}`);
  const miw = !mir ? null : (mir.right - mir.left) / 2;
  const left = !miw ? -1 : mir.left + miw;
  return show ? <div className="arrow-up" style={{ left }} /> : React.Fragment;
};

class MenuProducts extends React.Component {
  render() {
    const {
      windowSize,
      brands,
      categories,
      history,
      onMouseEnter,
      onMouseLeave
    } = this.props;

    return (
      <div
        className="header-menu"
        style={configStyle.header.menu}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Container>
          <Search loadFromUrl history={history} link={"/productos"} />
        </Container>
        <Divider min />
        <Container>
          <Categories windowSize={windowSize} categories={categories} />
        </Container>
        <Divider marginTop min />
        <Container>
          <Brands brands={brands} />
        </Container>
      </div>
    );
  }
}

class MenuThreeD extends React.Component {
  render() {
    const { onMouseEnter, onMouseLeave } = this.props;

    return (
      <div
        className="header-menu"
        style={configStyle.header.menu}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Container>
          <ThreeD />
        </Container>
      </div>
    );
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scroll: false,
      anchorEl: null,
      cart: false,
      menu: false
    };

    this.refArrowUp = React.createRef();
  }

  checkMin = () => {
    const { scroll } = this.state;
    const scrollY = window.pageYOffset;

    const newScroll = scrollY > scrollYMin;

    if (scroll !== newScroll) this.setState({ scroll: newScroll });
  };

  handleOpen = event => {
    this.setState({ anchorEl: event.currentTarget.id });
  };

  handleClose = event => {
    this.setState({ anchorEl: null });
  };

  toggleDrawer = (side, open) => () => {
    this.setState({ [side]: open });
  };

  componentDidMount() {
    this.node = ReactDOM.findDOMNode(this);
    this.checkMin();
    window.addEventListener("scroll", this.checkMin);
  }

  render() {
    const {
      windowSize,
      showCartDrawer,
      userIsAuthenticated,
      absolute,
      transparent,
      minimal,
      cartItemsCount,
      cartTotal,
      user,
      history,
      brands,
      categories,
      userLogout
    } = this.props;

    const { scroll, cart, menu, anchorEl } = this.state;
    const headerTransparent = transparent && !scroll;
    const showOptions =
      (windowSize === "lg" || windowSize === "xl") && !minimal;
    const showUserActions = !minimal;
    const showActionsLabels = windowSize === "lg" || windowSize === "xl";
    const showUserButton = windowSize === "lg" || windowSize === "xl";
    const showMenuButton =
      !minimal &&
      (windowSize === "xs" || windowSize === "sm" || windowSize === "md");
    const options = configOtionsMenu.map(m => {
      switch (m.menu) {
        case "PRO":
          return {
            ...m,
            onOver: true,
            onClick: true,
            menu: (
              <MenuProducts
                windowSize={windowSize}
                history={history}
                brands={brands}
                categories={categories}
                onMouseEnter={this.handleEnter}
                onMouseLeave={this.handleClose}
              />
            )
          };
        case "3D":
          return {
            ...m,
            onOver: true,
            onClick: true,
            menu: (
              <MenuThreeD
                windowSize={windowSize}
                onMouseEnter={this.handleEnter}
                onMouseLeave={this.handleClose}
              />
            )
          };
        default:
          return { ...m };
      }
    });
    const sct = "rgba(255, 255, 255, 0.7)";
    const styleHeaderTransparent = headerTransparent
      ? {
          backgroundColor: "transparent",
          boxShadow: "none",
          color: sct,
          ":hover": {
            color: "white"
          }
        }
      : {};
    const styleHeaderATransparent = headerTransparent
      ? {
          color: sct,
          ":hover": {
            color: "white"
          }
        }
      : {};
    const styleHeaderNavItemTransparent = { ...styleHeaderATransparent };
    return (
      <StyleRoot>
        <header
          className={`${absolute ? "absolute" : ""} ${windowSize} ${
            scroll ? "mh" : ""
          }`}
        >
          <div
            className={`header-content ${
              headerTransparent ? "transparent" : ""
            }`}
            style={[configStyle.header, styleHeaderTransparent]}
          >
            <Container>
              <Navbar>
                <Row noGutters>
                  {showMenuButton && (
                    <Col xs="auto">
                      <Nav className="user-actions">
                        <NavItem
                          className="user-actions-menu"
                          style={[
                            configStyle.header.navItem,
                            styleHeaderNavItemTransparent,
                            { marginLeft: 4 }
                          ]}
                          onClick={this.toggleDrawer("menu", true)}
                        >
                          <Icon>bars</Icon>
                        </NavItem>
                      </Nav>
                    </Col>
                  )}
                  <Col xs={true} lg="auto" className="text-center">
                    <Link to="/">
                      <img
                        src={
                          headerTransparent
                            ? configLogo.transparent
                            : configLogo.default
                        }
                        className="logo"
                        alt={configName}
                      />
                    </Link>
                  </Col>
                  {showOptions && (
                    <Col>
                      <Nav className="options">
                        {options.map((o, i) => {
                          const itemId = `nav-item-${i}`;
                          const menuIsOpen = anchorEl === itemId;
                          return (
                            <NavItem
                              key={i}
                              id={itemId}
                              style={[
                                configStyle.header.navItem,
                                styleHeaderNavItemTransparent
                              ]}
                              onMouseOver={o.onOver ? this.handleOpen : null}
                              onMouseLeave={o.onOver ? this.handleClose : null}
                            >
                              {o.onClick ? (
                                o.name
                              ) : o.link.indexOf("http") !== -1 ? (
                                <a
                                  key={i}
                                  style={[
                                    configStyle.header.a,
                                    styleHeaderATransparent
                                  ]}
                                  href={o.link}
                                >
                                  {o.name}
                                </a>
                              ) : (
                                <Link
                                  key={i}
                                  style={[
                                    configStyle.header.a,
                                    styleHeaderATransparent
                                  ]}
                                  to={o.link}
                                >
                                  {o.name}
                                </Link>
                              )}
                              {menuIsOpen && (
                                <div>
                                  <div className="header-menu-container" />
                                  <ArrowUp
                                    show={headerTransparent && !scroll}
                                    node={this.node}
                                    element={itemId}
                                  />
                                  {o.menu}
                                </div>
                              )}
                              {/* {o.menu} */}
                            </NavItem>
                          );
                        })}
                      </Nav>
                    </Col>
                  )}
                  {showUserActions && (
                    <Col xs="auto" className="user-actions">
                      <Nav>
                        {showUserButton && (
                          <NavItem
                            id="nav-item-user"
                            onMouseOver={
                              userIsAuthenticated ? this.handleOpen : null
                            }
                            onMouseLeave={
                              userIsAuthenticated ? this.handleClose : null
                            }
                            onClick={
                              userIsAuthenticated ? this.handleOpen : null
                            }
                          >
                            {userIsAuthenticated ? (
                              <div>
                                <Avatar
                                  className="avatar"
                                  style={configStyle.secondary}
                                >
                                  {stringFirstLetter(user.firstName)}
                                </Avatar>
                                {anchorEl === "nav-item-user" && (
                                  <div>
                                    <ArrowUp
                                      show={headerTransparent && !scroll}
                                      node={this.node}
                                      element="nav-item-user"
                                    />
                                    <div
                                      className="header-menu floating"
                                      style={configStyle.header.menu}
                                      onMouseEnter={this.handleEnter}
                                      onMouseLeave={this.handleClose}
                                    >
                                      <Container>
                                        <Row>
                                          <Col>
                                            <div className="header-menu-item-title">
                                              {user.firstName +
                                                " " +
                                                user.lastName}
                                              <div className="header-menu-item-subtitle">
                                                {user.email}
                                              </div>
                                            </div>
                                            <ul>
                                              <Link
                                                style={configStyle.menu.item}
                                                to="/perfil"
                                              >
                                                <li className="header-menu-item-subitem">
                                                  Perfil
                                                </li>
                                              </Link>
                                              <Link
                                                style={configStyle.menu.item}
                                                to="/mis-compras"
                                              >
                                                <li className="header-menu-item-subitem">
                                                  Mis compras
                                                </li>
                                              </Link>
                                              {// Seller
                                              user &&
                                                user.isSeller &&
                                                user.person.employeeOf.length >
                                                  0 && (
                                                  <div>
                                                    {user.person.employeeOf.map(
                                                      (c, i) => {
                                                        return (
                                                          <div key={i}>
                                                            <Link
                                                              style={
                                                                configStyle.menu
                                                                  .item
                                                              }
                                                              to={`/${
                                                                c.id
                                                              }/pedidos`}
                                                            >
                                                              <li className="header-menu-item-subitem">
                                                                {c.name}
                                                              </li>
                                                            </Link>
                                                            <Link
                                                              style={
                                                                configStyle.menu
                                                                  .item
                                                              }
                                                              to={`/${
                                                                c.id
                                                              }/config`}
                                                            >
                                                              <li className="header-menu-item-subitem">
                                                                Configuración
                                                              </li>
                                                            </Link>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                )}
                                              <li
                                                className="header-menu-item-subitem"
                                                style={configStyle.menu.item}
                                                onClick={() => userLogout()}
                                              >
                                                Salir
                                              </li>
                                            </ul>
                                          </Col>
                                        </Row>
                                      </Container>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <Link
                                style={[
                                  configStyle.header.a,
                                  styleHeaderATransparent
                                ]}
                                to="/iniciar"
                              >
                                <Icon>user</Icon>
                                {showActionsLabels && <span>Ingresar</span>}
                              </Link>
                            )}
                          </NavItem>
                        )}
                        <NavItem
                          style={[
                            configStyle.header.a,
                            styleHeaderATransparent
                          ]}
                          onClick={this.toggleDrawer("cart", true)}
                        >
                          {cartItemsCount === 0 ? (
                            <Icon>shopping-bag</Icon>
                          ) : (
                            <span>
                              <Icon>shopping-bag</Icon>
                              <Avatar
                                className="badge"
                                style={configStyle.secondary}
                              >
                                {cartItemsCount}
                              </Avatar>
                            </span>
                          )}
                          {showActionsLabels && (
                            <span>
                              <small>U$S</small>{" "}
                              {numberFormat(numberTypes.PRICE, cartTotal)}
                            </span>
                          )}
                        </NavItem>
                      </Nav>
                    </Col>
                  )}
                </Row>
              </Navbar>
            </Container>
          </div>
          <Drawer
            open={menu}
            elevation={2}
            anchor="left"
            onClose={this.toggleDrawer("menu", false)}
          >
            <DrawerMenu
              userIsAuthenticated={userIsAuthenticated}
              cartItemsCount={cartItemsCount}
              cartTotal={cartTotal}
              user={user}
              options={options}
              userLogout={userLogout}
            />
          </Drawer>
          {showCartDrawer && (
            <Drawer
              open={cart}
              elevation={2}
              anchor="right"
              onClose={this.toggleDrawer("cart", false)}
            >
              <CartTable drawerMode />
            </Drawer>
          )}
        </header>
      </StyleRoot>
    );
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.checkMin);
  }
}

Header.defaultProps = {
  showCartDrawer: true
};

export default Radium(Header);
