import React from "react";
import { Container, Row, Col } from "reactstrap";

import Loading from "../../Loading";

class CompanyGroupList extends React.Component {
  state = {};

  render() {
    const { isLoading, advanced, companyGroups, onSelect } = this.props;
    return (
      <div className="company-group-list">
        {isLoading ? (
          <Loading />
        ) : (
          <Container className="list">
            {companyGroups.map((cg, cgi) => {
              return (
                <Row key={cgi} onClick={() => onSelect(cg)}>
                  <Col xs={advanced ? 2 : 12}>{cg.name}</Col>
                  {advanced && (
                    <Col>
                      {cg.companies.map((c, ci) => (
                        <Row key={ci}>
                          <Col>
                            <Row>
                              <Col>{c.name}</Col>
                              <Col>{c.defaultAddress}</Col>
                              <Col>{c.defaultPhone}</Col>
                              <Col>{c.defaultEmail}</Col>
                            </Row>
                          </Col>
                          {/* <Col>
                            <Row>
                              <Col>Ignacio Rodriguez</Col>
                              <Col>ignacio@sircal.com.uy</Col>
                              <Col>092907330</Col>
                            </Row>
                          </Col> */}
                        </Row>
                      ))}
                    </Col>
                  )}
                </Row>
              );
            })}
          </Container>
        )}
      </div>
    );
  }
}

export default CompanyGroupList;
