import React from "react";
import { Container, Row, Col } from "reactstrap";
import Icon from "../Icon";

import "./style.css";

const items = [
  {
    icon: "clock",
    outline: true,
    hideMin: true,
    name: "Horario",
    name2: "L a V de 09:00 a 18:00 hs"
  },
  {
    brand: true,
    icon: "instagram",
    link: "https://www.instagram.com/robotecuruguay/"
  },
  {
    brand: true,
    icon: "twitter",
    link: "https://www.twitter.com/robotecu/"
  },
  {
    brand: true,
    icon: "facebook",
    link: "https://www.facebook.com/robotecuruguay/"
  },
  {
    brand: false,
    hideMin: true,
    name: "Joanicó 3256",
    name2: "Montevideo, Uruguay",
    icon: "map-marker-alt"
  },
  {
    brand: true,
    name: "099 263 226",
    icon: "whatsapp",
    link: "https://wa.me/59899263226/"
  },
  {
    brand: false,
    name: "2487 5489",
    icon: "phone",
    link: "tel:+59824875489"
  }
];

const Contacts = props => {
  const { extended } = props;
  return (
    <div className="contacts">
      {!extended ? (
        <Container>
          <Row>
            {items.filter(c => !c.hideMin).map(({ brand, icon, link }, i) => (
              <Col key={i} className={`contact-${icon}`}>
                <a href={link} rel="noopener noreferrer" target="_blank">
                  <Icon brand={brand}>{icon}</Icon>
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      ) : (
        <div>
          <div className="contacts-names">
            {items
              .filter(c => c.name)
              .map(({ brand, name, name2, icon, outline, link }, i) => (
                <a
                  key={i}
                  href={link}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={`contact-${icon}`}
                >
                  <Row>
                    <Col xs="3" className="text-center">
                      <Icon brand={brand} outline={outline}>
                        {icon}
                      </Icon>
                    </Col>
                    <Col>
                    <span className={`contacts-names-name ${name2 ? '' : 'mt'}`}>{name}</span>
                      <br />
                      <span className="contacts-names-name2">{name2}</span>
                    </Col>
                  </Row>
                </a>
              ))}
          </div>
          <Row>
            {items.filter(c => !c.name).map(({ brand, icon, link }, i) => (
              <Col
                key={i}
                xs={extended ? "" : "auto"}
                className={`text-center contact-${icon}`}
              >
                <a href={link} rel="noopener noreferrer" target="_blank">
                  <Icon brand={brand}>{icon}</Icon>
                </a>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
};

export default Contacts;
