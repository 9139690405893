import React from "react";
import { Stock } from "base";
import Render from "./render";

class ProductCard extends React.Component {
  render() {
    return (
      <Stock>
        <Render {...this.props} />
      </Stock>
    );
  }
}
export default ProductCard;
