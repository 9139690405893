import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

import MasterPage from "../MasterPage";

import "./style.css";

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = "Robotec";
  }

  render() {
    return (
      <MasterPage>
        <div className="not-found">
          <Container>
            <Row>
              <Col className="text-center">
                <h2>Parece que esta página no existe</h2>
                <Link to="/">Ir a la página principal</Link>
              </Col>
            </Row>
          </Container>
        </div>
      </MasterPage>
    );
  }
}

export default NotFoundPage;
