import React from "react";
import { Row, Col } from "reactstrap";

import Chip from "@material-ui/core/Chip";

import "./style.css";

class Filters extends React.Component {
  state = {};

  render() {
    const { filters, deleteValueItem } = this.props;
    return (
      <div className="filters">
        <div className="d-none d-lg-block filters-actions">
          {filters
            .filter(
              f =>
                f.current &&
                f.current.value &&
                Array.isArray(f.current.value) &&
                f.current.value.length > 0
            )
            .map((f, fi) => (
              <Row key={fi} noGutters>
                <Col xs="auto">
                  <div key={fi} className="filter-option">
                    <div className="filter-option-title">{f.title}</div>
                  </div>
                </Col>
                {f.current.value.map((v, vi) => (
                  <Col xs="auto" key={vi}>
                    <Chip
                      label={v.name}
                      className="filter-option-chip"
                      onDelete={() => deleteValueItem(f.type, v.id)}
                    />
                  </Col>
                ))}
              </Row>
            ))}
        </div>
      </div>
    );
  }
}

export default Filters;
