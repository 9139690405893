import React from "react";

import { Chat } from "base";

import Header from "../../components/Header";
import GoTop from "../../components/GoTop";
import Footer from "../../components/Footer";
import Telemarketing from "../../components/Telemarketing";

import "./style.css";

class MasterPage extends React.Component {
  componentDidMount() {
    Chat();
  }

  render() {
    const {
      headerProps,
      contentPaddingTop,
      contentPaddingBottom,
      hideFooter,
      hideGoTop,
      hideTelemarketing,
      children
    } = this.props;

    return (
      <div>
        <Header {...headerProps} />
        <div
          className={`master-content ${contentPaddingTop ? "pt" : ""} ${
            contentPaddingBottom ? "pb" : ""
          }`}
        >
          {children}
        </div>
        {!hideFooter && <Footer />}
        {!hideGoTop && <GoTop />}
        {!hideTelemarketing && <Telemarketing />}
      </div>
    );
  }
}

MasterPage.defaultProps = {
  contentPaddingTop: true,
  contentPaddingBottom: true,
  hideFooter: false,
  hideGoTop: false,
  hideTelemarketing: false
};

export default MasterPage;
