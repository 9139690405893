import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, IconButton } from "@material-ui/core";

import Search from "../../Search";
import ModalCompanyGroupAdd from "../ModalCompanyGroupAdd";
import CompanyGroupList from "../CompanyGroupList";

import Icon from "../../Icon";
import Filters from "../Filters";

import "./style.css";

class ModalCompanyGroupSelect extends React.Component {
  state = { newIsOpen: false };

  render() {
    const {
      advanced,
      rounded,
      showFooter,
      title,
      onClose,
      onSelect
    } = this.props;
    const { newIsOpen } = this.state;

    return newIsOpen ? (
      <ModalCompanyGroupAdd
        onClose={() => this.setState({ newIsOpen: false })}
        onSuccess={companyGroup => onSelect(companyGroup)}
      />
    ) : (
      <Modal
        isOpen={true}
        className={`card ${
          rounded ? "rounded" : ""
        } modal-company-group-select`}
        size={advanced ? "xl" : "md"}
      >
        {title && <div className="modal-top-title">{title}</div>}
        {!showFooter && (
          <IconButton className="modal-top-close" onClick={onClose}>
            <Icon>times</Icon>
          </IconButton>
        )}
        <ModalHeader>
          <Search
            autoComplete={false}
            rounded
            zeroLength
            showFilter={advanced}
          />
        </ModalHeader>
        <ModalBody className="no-padding">
          <Filters />
          <CompanyGroupList
            min={!advanced}
            advanced={advanced}
            onSelect={onSelect}
          />
        </ModalBody>
        {showFooter && (
          <ModalFooter>
            <div className="modal-footer-right">
              <Button onClick={onClose}>Cancelar</Button>
              <Button
                color="primary"
                onClick={() => this.setState({ newIsOpen: true })}
              >
                Nuevo
              </Button>
            </div>
          </ModalFooter>
        )}
      </Modal>
    );
  }
}

export default ModalCompanyGroupSelect;
