import { configId } from "base";
import robotec from "./robotec";
import robotecJuguetes from "./robotecJuguetes";
import robotecAcademyRoutes from "./robotecAcademy";
import nami from "./nami";

export default () => {
  switch (configId) {
    case "RTC":
      return robotec;
    case "RTC_DEV":
      return robotec;
    //-------------------------------------------
    case "RTJ":
      return robotecJuguetes;
    case "RTJ_DEV":
      return robotecJuguetes;
    //-------------------------------------------
    case "RTC_ACA":
      return robotecAcademyRoutes;
    //-------------------------------------------
    case "NAM":
      return nami;
    case "NAM_DEV":
      return nami;
    default:
      return [];
  }
};
